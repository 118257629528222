import type { PromoCode } from "~/api_gen";

export const usePromocodesStore = defineStore("promocodesStore", () => {
  const { t } = useI18n();
  const promocodeWindowIsRequired = ref(false);
  const userStore = useUserStore();
  const promocodeData = ref<PromoCode | undefined>(undefined);

  function activatePromocode(promocode: string) {
    const api = useApi();
    return api.promoCodes
      .codesActivate({
        activatePromoCodeRequest: {
          code: promocode,
        },
      })
      .then((response) => {
        const calendarStore = useCalendarStore();
        const bullpassStore = useBullpassStore();
        userStore.getUser();
        calendarStore.getCalendarStatistics();
        bullpassStore.getEventInfoWithAuth();
        return response;
      });
  }

  function cancelPromocode() {
    const api = useApi();
    return api.promoCodes.codesDeactivate().then((response) => {
      userStore.getUser();
      return response;
    });
  }

  async function showPromocodePopup(promocode?: string) {
    const api = useApi();
    if (promocode) {
      const notify = useNotify();

      if (!userStore.userIsAuthorized) {
        notify({
          type: "error",
          text: t("auth.errors.auth_needed"),
        });
      } else {
        try {
          promocodeData.value = await api.promoCodes.codesShow({
            activatePromoCodeRequest: {
              code: promocode,
            },
          });
        } catch (e: any) {
          const errorData = await e.response.json();
          if (Array.isArray(errorData?.code) && errorData?.code.length) {
            notify({
              type: "error",
              text: errorData.code[0],
            });
          } else if (typeof errorData?.code === "string" && errorData?.code) {
            notify({
              type: "error",
              text: errorData.code,
            });
          } else {
            notify({
              type: "error",
              text: t("promocode.failed_to_activate"),
            });
          }
        }
      }
    }

    document.documentElement.style.overflow = "hidden";
    promocodeWindowIsRequired.value = true;
  }

  function hidePromocodeWindow() {
    promocodeWindowIsRequired.value = false;
    document.documentElement.style.overflow = "";
  }

  return {
    promocodeWindowIsRequired,
    activatePromocode,
    cancelPromocode,
    showPromocodePopup,
    hidePromocodeWindow,
    promocodeData,
  };
});
