import * as Sentry from "@sentry/vue";

export const useCalendarStore = defineStore("calendarStore", () => {
  const ticketsCount = ref<number>(0);
  const ticketsCountTotal = ref<number>(0);
  const jackpotChance = ref<number>(0);
  const prizeChance = ref<number>(0);
  const lotteryPriseIsRequired = ref<boolean>(false);
  const lotteryPrise = ref<number>(0);
  const api = useApi();
  function getCalendarStatistics() {
    api.calendar.profileRetrieve().then((res) => {
      ticketsCount.value = res.ticketsCount;
      prizeChance.value = res.prizeChance;
      ticketsCountTotal.value = res.ticketsCountTotal;
      jackpotChance.value = res.jackpotChance;
    });
  }

  function getLotteryReward() {
    const userStore = useUserStore();
    return api.calendar
      .takeTicketsRewardList()
      .then((data) => {
        if (!data || !Array.isArray(data) || data.length === 0) return;
        lotteryPriseIsRequired.value = true;
        lotteryPrise.value = data.reduce(
          (acc, item) => acc + item.rewardAmount,
          0,
        );
        userStore.getUser();
      })
      .catch((e) => Sentry.captureException(e));
  }

  return {
    ticketsCount,
    ticketsCountTotal,
    jackpotChance,
    prizeChance,
    lotteryPriseIsRequired,
    lotteryPrise,
    getCalendarStatistics,
    getLotteryReward,
  };
});
